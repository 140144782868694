<template>
  <div>
    <CyNotification
      theme="error"
      :content="errors.requireOrganizationMFA"/>

    <section class="two-column-section">
      <header class="two-column-section__header">
        <h2 class="h5">
          {{ $t('password.sectionTitle') }}
        </h2>
      </header>

      <div class="two-column-section__content">
        <CyFormsProfileChangePassword/>
      </div>
    </section>

    <v-divider class="my-8"/>

    <section class="two-column-section">
      <header class="two-column-section__header">
        <h2 class="h5">
          {{ $t('MFA.sectionTitle') }}
        </h2>
        <p class="subtitle">
          {{ $t('MFA.sectionSubtitle') }}
        </p>
      </header>

      <div class="two-column-section__content">
        <CyNotification
          v-if="hasOrganizationWithMFAEnabled"
          theme="warning"
          :title="$t('MFA.warningMFARequired.title')"
          :content="$t('MFA.warningMFARequired.content')"/>

        <v-card
          outlined
          class="d-flex pa-4 space-x-4">
          <v-avatar
            class="avatar"
            size="32"
            rounded>
            <v-icon
              color="white"
              size="24">
              mail_outline
            </v-icon>
          </v-avatar>

          <div class="space-y-2 flex-grow-1">
            <div class="d-flex align-center space-x-2">
              <h3 class="h6">
                {{ $t('MFA.emailFactor.title') }}
              </h3>
              <CyTag
                v-if="userProfile.mfa_enabled"
                variant="success"
                icon-before="check">
                {{ $t('MFA.emailFactor.enabledTagLabel') }}
              </CyTag>
            </div>
            <p class="mb-0">
              {{ $t('MFA.emailFactor.description') }}
            </p>
          </div>

          <CyButton
            variant="secondary"
            theme="primary"
            :loading="isUpdatingEmailMFA"
            @click.native="onEmailMFAButtonClick">
            {{ userProfile.mfa_enabled ? _.upperFirst($t('forms.btnDisable')) : _.upperFirst($t('forms.btnEnable')) }}
          </CyButton>
        </v-card>

        <CyModal
          v-if="isEmailMFAConfirmModalVisible"
          :header-title="$t('MFA.emailMFAConfirmModal.title')"
          :action-btn-func="() => toggleEmailMFA(true)"
          :action-btn-text="$t('MFA.emailMFAConfirmModal.actionBtnText')"
          :cancel-btn-func="() => $toggle.isEmailMFAConfirmModalVisible(false)"
          :cancel-btn-text="$t('forms.btnCancel')"
          :close-modal-on-action-click="false"
          :loading="isUpdatingEmailMFA"
          modal-type="info">
          <template slot="default">
            <p v-html="$sanitizeHtml($t('MFA.emailMFAConfirmModal.paragraph', { email: primaryEmailAddress }))"/>
            <p>{{ $t('MFA.emailMFAConfirmModal.paragraph2') }}</p>
          </template>
        </CyModal>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { constructBreadcrumb } from '@/utils/helpers'
import CyFormsProfileChangePassword from '@/components/forms/profile-change-password.vue'

export default {
  name: 'CyPageUserSecurity',
  components: {
    CyFormsProfileChangePassword,
  },
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.$t('routes.userSecurity'), [
      {
        label: this.$t('routes.profile'),
        name: 'profile',
      },
    ])
  },
  data: () => ({
    isEmailMFAConfirmModalVisible: false,
    isUpdatingEmailMFA: false,
  }),
  computed: {
    ...mapState('user', {
      userProfile: (state) => state.profile,
    }),
    ...mapState({
      errors: (state) => state.errors,
    }),
    ...mapState({
      organizations: (state) => state.organizations,
    }),
    hasOrganizationWithMFAEnabled () {
      return _.some(this.organizations, { mfa_enabled: true })
    },
  },
  created () {
    this.profile = _.cloneDeep(this.userProfile)
    this.primaryEmailAddress = _.find(this.userProfile.emails, { purpose: 'primary' })?.email
  },
  methods: {
    ...mapActions('user', [
      'UPDATE_PROFILE',
    ]),
    onEmailMFAButtonClick () {
      this.userProfile.mfa_enabled
        ? this.toggleEmailMFA(false)
        : this.isEmailMFAConfirmModalVisible = true
    },
    async toggleEmailMFA (enable) {
      this.isUpdatingEmailMFA = true
      this.profile.mfa_enabled = enable
      const { profile } = this
      await this.UPDATE_PROFILE({ profile })
      this.isUpdatingEmailMFA = false
      if (enable) {
        this.isEmailMFAConfirmModalVisible = false
      }
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.userSecurity',
        password: {
          sectionTitle: '@:forms.fieldPassword',
        },
        MFA: {
          sectionTitle: 'Multi-factor authentication',
          sectionSubtitle: 'Improve the security of your account by enabling additional authentication methods.',
          warningMFARequired: {
            title: 'MFA is required by some of your organizations.',
            content: 'You are part of organizations that require Multi-factor authentication. MFA must be enabled to access them.',
          },
          emailFactor: {
            title: '@:forms.fieldEmail',
            description: 'After submitting your email and password, you will receive an email message containing a temporary login link.',
            enabledTagLabel: '@:enabled',
          },
          emailMFAConfirmModal: {
            title: 'Enable Email Multi-factor authentication?',
            paragraph: 'Enabling Multi-factor authentication will log you out, and you will need to log in again. After entering your email and password, a temporary login link will be sent to your primary email address <strong>{email}</strong> to complete the authentication.',
            paragraph2: 'Please ensure that you can access this email address before enabling Email Multi-factor authentication.',
            actionBtnText: 'Enable Email authentication',
          },
        },
      },
      es: {
        title: '@:routes.userSecurity',
        password: {
          sectionTitle: '@:forms.fieldPassword',
        },
        MFA: {
          sectionTitle: 'Autenticación multifactorial',
          sectionSubtitle: 'Mejora la seguridad de tu cuenta habilitando métodos de autenticación adicionales.',
          warningMFARequired: {
            title: 'Se requiere MFA en algunas de tus organizaciones.',
            content: 'Eres parte de organizaciones que requieren autenticación multifactorial. Debes habilitar MFA para acceder a ellas.',
          },
          emailFactor: {
            title: '@:forms.fieldEmail',
            description: 'Después de enviar tu correo electrónico y contraseña, recibirás un mensaje de correo electrónico que contiene un enlace temporal de inicio de sesión.',
            enabledTagLabel: '@:enabled',
          },
          emailMFAConfirmModal: {
            title: '¿Deseas habilitar la autenticación multifactorial por correo electrónico?',
            paragraph: 'La habilitación de la autenticación multifactorial te desconectará y deberás iniciar sesión nuevamente. Después de ingresar tu correo electrónico y contraseña, se enviará un enlace temporal de inicio de sesión a tu dirección de correo electrónico principal <strong>{email}</strong> para completar la autenticación.',
            paragraph2: 'Asegúrate de poder acceder a esta dirección de correo electrónico antes de habilitar la autenticación multifactorial por correo electrónico.',
            actionBtnText: 'Habilitar autenticación de correo electrónico',
          },
        },
      },
      fr: {
        title: '@:routes.userSecurity',
        password: {
          sectionTitle: '@:forms.fieldPassword',
        },
        MFA: {
          sectionTitle: 'Authentification multifactorielle',
          sectionSubtitle: `Améliorez la sécurité de votre compte en activant des méthodes d'authentification supplémentaires.`,
          warningMFARequired: {
            title: 'La MFA est requise par certaines de vos organisations.',
            content: `Vous faites partie d'organisations qui exigent une authentification multi-facteurs. La MFA doit être activée pour y accéder.`,
          },
          emailFactor: {
            title: '@:forms.fieldEmail',
            description: 'Après avoir saisi votre adresse e-mail et votre mot de passe, vous recevrez un message e-mail contenant un lien de connexion temporaire.',
            enabledTagLabel: '@:enabled',
          },
          emailMFAConfirmModal: {
            title: `Activer l'authentification multifactorielle par e-mail ?`,
            paragraph: `L'activation de l'authentification multifactorielle vous déconnectera, et vous devrez vous reconnecter. Après avoir saisi votre adresse e-mail et votre mot de passe, un lien de connexion temporaire sera envoyé à votre adresse e-mail principale <strong>{email}</strong> pour finaliser l'authentification.`,
            paragraph2: `Veuillez vous assurer que vous pouvez accéder à cette adresse e-mail avant d'activer l'authentification multifactorielle par e-mail.`,
            actionBtnText: `Activer l'authentification par e-mail`,
          },
        },
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.avatar {
  border: solid 1px get-color("white");
  background-color: get-color("primary", "light-3");
}
</style>
