<template>
  <v-card
    outlined
    class="pa-4 space-y-2"
    tag="form"
    @submit.prevent="updateProfile">
    <CyNotification
      theme="error"
      :content="errors"/>

    <v-text-field
      v-model="formContent.oldPassword"
      :label="$t('fieldOldPassword')"
      :error-messages="oldPasswordErrors"
      :append-icon="formContent.oldPasswordVisibility ? 'visibility_off' : 'visibility'"
      :type="formContent.oldPasswordVisibility ? 'text' : 'password'"
      class="required-field"
      @click:append="$toggle.formContent.oldPasswordVisibility"
      @blur="$v.formContent.oldPassword.$touch()"/>

    <v-text-field
      v-model="formContent.newPassword"
      :label="$t('fieldNewPassword')"
      :error-messages="newPasswordErrors"
      :append-icon="formContent.newPasswordVisibility ? 'visibility_off' : 'visibility'"
      :type="formContent.newPasswordVisibility ? 'text' : 'password'"
      class="required-field"
      @click:append="$toggle.formContent.newPasswordVisibility"
      @blur="$v.formContent.newPassword.$touch()"/>

    <CyPasswordStrength
      v-model="passwordStrength"
      class="mx-0"
      :password="formContent.newPassword"/>

    <v-text-field
      v-model="formContent.confirmPassword"
      :label="$t('fieldConfirmPassword')"
      :error-messages="confirmPasswordErrors"
      :append-icon="formContent.confirmPasswordVisibility ? 'visibility_off' : 'visibility'"
      :type="formContent.confirmPasswordVisibility ? 'text' : 'password'"
      class="required-field"
      @click:append="$toggle.formContent.confirmPasswordVisibility"
      @blur="$v.formContent.confirmPassword.$touch()"/>

    <CyButton
      variant="secondary"
      theme="primary"
      icon="lock"
      type="submit"
      :loading="isUpdating">
      {{ $t('buttonChangePassword') }}
    </CyButton>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { required, sameAs, minLength, not } from 'vuelidate/lib/validators'
import CyPasswordStrength from '@/components/password-strength'

export default {
  name: 'CyFormsProfileChangePassword',
  components: {
    CyPasswordStrength,
  },
  validations () {
    return {
      formContent: {
        oldPassword: {
          required,
        },
        newPassword: {
          required,
          minLength: minLength(8),
          notSameAsOldPassword: not(sameAs('oldPassword')),
          minStrength () {
            return this.passwordStrength > 0
          },
        },
        confirmPassword: {
          required,
          sameAsPassword: sameAs('newPassword'),
        },
      },
    }
  },
  data: () => ({
    formContent: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      oldPasswordVisibility: false,
      newPasswordVisibility: false,
      confirmPasswordVisibility: false,
    },
    passwordStrength: 0,
    isUpdating: false,
  }),
  computed: {
    ...mapState('user', {
      profile: (state) => state.profile,
      errors: (state) => state.errors.profile,
    }),
    oldPasswordErrors () {
      const { $dirty, required } = this.$v.formContent.oldPassword
      const errors = []
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
    newPasswordErrors () {
      const { $dirty, required, minLength, notSameAsOldPassword, minStrength } = this.$v.formContent.newPassword
      const errors = []
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      if (!minLength) errors.push(this.$t('fieldPasswordMinLength'))
      if (!notSameAsOldPassword) errors.push(this.$t('fieldNewPasswordSameAsOldPassword'))
      if (!minStrength) errors.push(this.$t('fieldPasswordStrength'))
      return errors
    },
    confirmPasswordErrors () {
      const { $dirty, required, sameAsPassword } = this.$v.formContent.confirmPassword
      const errors = []
      if (!$dirty) return errors
      if (!sameAsPassword) errors.push(this.$t('fieldConfirmPasswordSameAsPassword'))
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
  },
  beforeDestroy () {
    this.CLEAR_USER_ERRORS()
  },
  methods: {
    ...mapActions('user', [
      'UPDATE_PROFILE',
    ]),
    ...mapMutations('user', [
      'CLEAR_USER_ERRORS',
    ]),
    async updateProfile () {
      this.$v.$touch()

      if (this.$v.$invalid) return

      this.isUpdating = true
      const { oldPassword: current, newPassword } = this.formContent
      const profile = { ...this.profile, password_update: { current, new: newPassword } }
      await this.UPDATE_PROFILE({ profile, i18nProp: 'password.changed' })
      this.isUpdating = false
    },
  },
  i18n: {
    messages: {
      en: {
        fieldConfirmPassword: 'Confirm Password',
        fieldConfirmPasswordSameAsPassword: 'It should be the same as the password',
        fieldNewPassword: 'New Password',
        fieldNewPasswordSameAsOldPassword: 'It should be different than the old password',
        fieldOldPassword: 'Old Password',
        fieldPasswordMinLength: 'The password must have at least 8 characters',
        fieldPasswordStrength: 'The new password is too weak',
        buttonChangePassword: 'Change password',
      },
      es: {
        fieldConfirmPassword: 'Confirm Password',
        fieldConfirmPasswordSameAsPassword: 'La confirmación debe ser idéntica a la contraseña',
        fieldNewPassword: 'Nueva contraseña',
        fieldNewPasswordSameAsOldPassword: 'La contraseña debe ser diferente a la contraseña anterior',
        fieldOldPassword: 'Contraseña anterior',
        fieldPasswordMinLength: 'La contraseña debe tener al menos 8 caracteres',
        fieldPasswordStrength: 'La nueva contraseña es demasiado débil.',
        buttonChangePassword: 'Cambiar contraseña',
      },
      fr: {
        fieldConfirmPassword: 'Confirmez le mot de passe',
        fieldConfirmPasswordSameAsPassword: 'La confirmation doit être identique au mot de passe',
        fieldNewPassword: 'Nouveau mot de passe',
        fieldNewPasswordSameAsOldPassword: 'Le nouveau mot de passe doit différer du précédent',
        fieldOldPassword: 'Ancien mot de passe',
        fieldPasswordMinLength: 'Le mot de passe doit contenir au moins 8 caractères',
        fieldPasswordStrength: 'Le nouveau mot de passe est trop faible',
        buttonChangePassword: 'Changer le mot de passe',
      },
    },
  },
}
</script>
